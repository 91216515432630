.custom-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main {
    padding: 3rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-filter {
    margin-bottom: 43px;
}