.heading {
    font-size: 1.4rem;
    font-weight: 400;
}

.divider {
    font-size: 1.1rem;
    margin-left: 5px;
    margin-right: 5px;
}

.external-icon {
    margin-left: 5px;
    text-decoration: none;
}