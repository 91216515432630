.sortContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}

.headingContainer {
    display: flex;
    justify-content: space-between;
}

.inactive-sort {
    color: #ccc;
}

.sort-up {
    margin-bottom: -5px;
}

.sort-down {
    margin-top: -5px;
}